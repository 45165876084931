.submit-form {
    width: 30%;
    margin: auto;
    text-align: center;
}

.form-textfield {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    outline: none;
    background-color: #a5a1a1be;
    border: 0px;
    border-radius: 5px;
}

.form-button {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background-color: #ffb600;
    border: none;
    border-radius: 5px;
}

.message {
    height: 20px;
    text-align: center;
    margin-top: 30px;
    color: red;
}

.choose-one-tag {
    text-align: center;
    font-size: 20px;
}

.click-one-tag {
    margin-top: 30px;
}

.section-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 30px;
}

.sections {
    height: 30px;
    width: 22%;
    text-align: center;
    background-color: aquamarine;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.mapped-item {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 240px;
    min-height: 240px;
    padding: 5px;
    word-wrap: break-word;
    border-radius: 5px;
    width: 30%;
    background-color: #dfdfdfbe;
    overflow-y: hidden;
    cursor: pointer;
}

.mapped-item-title {
    max-height: 24px;
    min-height: 24px;
    text-align: center;
    font-size: 18px;
    word-wrap: break-word;
    font-weight: 600;
    overflow-y: hidden;
}

.mapped-item-content {
    margin-top: 10px;
    max-height: 120px;
    min-height: 120px;
    word-wrap: break-word;
    overflow-y:hidden;
}

.mapped-item-time {
    text-align: center;
}

.move-to {
    height: 30px;
    margin-top: 20px;
    width: max-content;
    margin: auto;
    cursor: pointer;
}

@media screen and (max-width:1200px) {
    .submit-form {
        width: 50%;
    }

    .mapped-item {
        width: 50%;
    }
}

@media screen and (max-width:800px) {
    .submit-form {
        width: 80%;
    }

    .mapped-item {
        width: 80%;
    }
}

@media screen and (max-width:600px) {
    .submit-form {
        width: 95%;
    }
    .sections {
        width: 45%;       
    }

    .mapped-item {
        width: 95%;
    }
}